import React from "react";

const ContactSection = () => {
  return (
    <section className='page-section' id='contactdet'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='embed-responsive embed-responsive-16by9'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m22!1m12!1m3!1d2185.0198788860785!2d-93.27379339631539!3d37.13769955501887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m7!3e0!4m0!4m4!2s37.13792550176842%2C%20-93.27324113706575!3m2!1d37.1379255!2d-93.27324109999999!5e0!3m2!1sen!2sus!4v1680639321267!5m2!1sen!2sus&z=14'
                width='100%'
                height='450'
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </div>
          </div>
          <div className='col-lg-6' id='contactarea'>
            <h2 className='section-heading text-uppercase'>Contact Us</h2>
            <h3 className='section-subheading'>We'd love to hear from you!</h3>
            <ul className='list-unstyled'>
              <li>
                <i className='fa fa-phone'></i>
                <a
                  href='#'
                  onClick={() => (window.location.href = "tel:4175010752")}
                >
                  (417) 501-0752
                </a>
              </li>
              <li>
                <i className='fa fa-envelope'></i>{" "}
                <a href='mailto:mrmonyman@mrmonyman.com'>
                  mrmonyman@mrmonyman.com
                </a>
              </li>
              <li>
                <i className='fa fa-map-marker'></i> 1339 E Republic Rd Ste A,
                Springfield, MO 65804{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
