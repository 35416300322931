import React from "react";
import { Carousel, Card } from "react-bootstrap";

function Reviews() {
  return (
    <div className='container'>
      <div className='text-center'>
        <h2 class='wbuy'></h2>
        <Carousel interval={8000}>
          <Carousel.Item>
            <Card>
              <Card.Body>
                {/*<Card.Title>Review 2</Card.Title>*/}{" "}
                <Card.Text>
                  Wonderful experience! Couldn't ask for a better business or
                  people to work with! - Gary in Ozark
                </Card.Text>
              </Card.Body>
              {/*<Card.Footer>
                <small className='text-muted'>Author 4</small>
  </Card.Footer>*/}
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card>
              <Card.Body>
                {/*<Card.Title>Review 2</Card.Title>*/}
                <Card.Text>
                  I would not hesitate to recommend them to anyone! - Tressa in
                  Nixa
                </Card.Text>
              </Card.Body>
              {/*<Card.Footer>
                <small className='text-muted'>Author 4</small>
  </Card.Footer>*/}
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card>
              <Card.Body>
                {/*<Card.Title>Review 2</Card.Title>*/}
                <Card.Text>
                  Great people! They care about their clients. Professional and
                  honest! Thank you for everything! - Jody in Springfield
                </Card.Text>
              </Card.Body>
              {/*<Card.Footer>
                <small className='text-muted'>Author 4</small>
  </Card.Footer>*/}
            </Card>
          </Carousel.Item>
        </Carousel>
      </div>
      <br />
    </div>
  );
}

export default Reviews;
