import React from "react";

class Cam extends React.Component {
  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = (event) => {
    if (event.data && event.data.type === "alert") {
      const message = event.data.message;
      alert(message); // Show the alert or perform the desired action
    }
  };
  render() {
    return (
      <div>
        <iframe
          src='https://mrmonyman.com/playground/up.html'
          title='Cam Frame'
          width='100%'
          height='400'
          sandbox='allow-same-origin allow-scripts'
        ></iframe>
      </div>
    );
  }
}

export default Cam;
