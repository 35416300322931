import React, { useState } from "react";

function InstructionsComponent() {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const toggleLightbox = () => {
    setLightboxOpen(!lightboxOpen);
  };

  return (
    <div>
      <a id='openButton' onClick={toggleLightbox}>
        Open Instructions
      </a>
      {lightboxOpen && (
        <div id='lightbox'>
          <div id='content'>
            <a id='closeButton' onClick={toggleLightbox}>
              Close
            </a>
            <br />
            <h2>Instructions</h2>
            <p>
              - Choose the preferred contact method by selecting either the
              "Email" or "SMS" option.
              <br />
              <br />
              - If you select "Email," enter your email address in the input
              field.
              <br />
              <br />
              - If you select "SMS," enter your phone number in the input field.
              <br />
              <br />
              - Click on the "Choose Picture" button. This will open your
              device's camera. Go ahead and take a picture of your objects.
              <br />
              <br />
              - Please be aware that we need at least 3 pictures. You may repeat
              the process as needed. The pictures will be put in a queue before
              being sent.
              <br />
              <br />
              - Once you have taken your pictures, they will be displayed in the
              preview area above the uploader.
              <br />
              <br />
              - Review the selected pictures in the preview area to ensure they
              are correct.
              <br />
              <br />
              - Click on the "Send Pictures" button to submit the selected
              pictures for estimation.
              <br />
              <br />
              - Wait for the uploader to process the pictures and send them to
              the appropriate destination.
              <br />- A success or error message will be displayed to inform you
              of the status.
            </p>
          </div>
        </div>
      )}

      <style>
        {`
        #lightbox {
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
        }

        #openButton {

        }

        #content {
          max-width: 800px;
          padding: 20px;
          color: #000000;
          text-align: center;
        }

        #closeButton {
          cursor: pointer;
        }
      `}
      </style>
    </div>
  );
}

export default InstructionsComponent;
