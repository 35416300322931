import React, { Component } from "react";

class TradingViewWidget extends Component {
  componentDidMount() {
    if (document.getElementById("tradingview-widget-script")) return;
    const script = document.createElement("script");
    script.id = "tradingview-widget-script";
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: false,
      locale: "en",
      largeChartUrl: "",
      isTransparent: true,
      showSymbolLogo: false,
      showFloatingTooltip: false,
      width: "220",
      height: "80",
      tabs: [
        {
          title: "Indices",
          symbols: [
            {
              s: "TVC:GOLD",
            },
          ],
          originalTitle: "Indices",
        },
      ],
    });
    document
      .getElementById("tradingview-widget-container__widget")
      .appendChild(script);
  }

  render() {
    return (
      <div
        id="tradingview-widget-container"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src="https://mrmonyman.com/assets/img/logo512.png"
          alt="Logo"
          width="50px"
        />
        <div id="tradingview-widget-container__widget"></div>
      </div>
    );
  }
}

export default TradingViewWidget;
