import React, { useState } from "react";
import "./App.css";
import TradingViewWidget from "./components/GoldPrice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPhone } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Reviews from "./components/Reviews";
import ContactSection from "./components/ContactSection";
import Cam from "./components/Cam";
import EstimateUploader from "./components/EstimateUploader";
import InstructionsComponent from "./components/InstructionsComponent";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleMenu() {
    console.log("Toggle menu");
    setIsOpen(!isOpen);
    console.log("Is open:", isOpen);
  }

  const [showCam, setShowCam] = useState(false);

  const handleShowCam = () => {
    setShowCam(true);
  };

  const handleHideCam = () => {
    setShowCam(false);
  };

  return (
    <div className='container'>
      <header>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, user-scalable=no'
        />
        <div
          id='mobmenarea'
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button class='styled-button' onClick={toggleMenu}>
            <FontAwesomeIcon id='menu-button' icon={faBars} />
          </button>
          <button
            class='styled-button'
            id='phon'
            onClick={() => (window.location.href = "tel:4177255010")}
          >
            <FontAwesomeIcon id='phoneicon' icon={faPhone} />
          </button>
        </div>
        <nav className={`mobile-menu ${isOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href='#' onClick={toggleMenu}>
                --Close Menu--
              </a>
            </li>
            <li>
              <a href='#about' onClick={toggleMenu}>
                About
              </a>
            </li>
            <li>
              <a href='#why' onClick={toggleMenu}>
                Why Us?
              </a>
            </li>
            <li>
              <a href='#prods' onClick={toggleMenu}>
                What We Buy
              </a>
            </li>
            <li>
              <a
                href='https://mrmonyman.com/process.html'
                target='_blank'
                onClick={toggleMenu}
              >
                Get more information
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <div id='my-tradingview-widget'>
        <TradingViewWidget />
      </div>
      <section className='hero'>
        <div className='hero-text'>
          <h2>Welcome to Confidential Gold Buyers</h2>
          <p>Your local trusted buyer of gold and silver!</p>
        </div>
        {/*<div className='hero-action'> 
          <a
            className='btn-primary'
            href='#'
            onClick={() => (window.location.href = "tel:4177255010")}
          >
            For more information, give us a call!
          </a>
        </div> */}
      </section>
      <main>
        <section id='about'>
          <h2>About Us</h2>
          <p>
            Transparency, integrity and straight forward communications has been
            part of my business principles for over 23 years. You'll get the
            utmost respect for your time and treasures from me, and my staff. I
            can pay more for your valuables due to my low overhead. Get paid
            more from me! We're one call or click away.
          </p>
        </section>
        <section id='why'>
          <h2>Why Us?</h2>
          <div class='row'>
            <div class='col-md-4'>
              <div class='text-center'>
                <div class='timeline-image'>
                  <img
                    class='rounded-circle img-fluid'
                    src='https://mrmonyman.com/assets/img/about/1.jpg'
                    alt='Profits going up.'
                  />
                </div>
                <div class='timeline-heading'>
                  <h4>Best Price & Service</h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>
                    Best price equals more money. Get paid the most dollars for
                    your gold and silver.
                  </p>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='text-center'>
                <div class='timeline-image'>
                  <img
                    class='rounded-circle img-fluid'
                    src='https://mrmonyman.com/assets/img/about/2.jpg'
                    alt='Two older people shaking hands'
                  />
                </div>
                <div class='timeline-heading'>
                  <h4>We serve you</h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>
                    Ready to answer any questions you may have on selling your
                    valuables.
                  </p>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='text-center'>
                <div class='timeline-image'>
                  <img
                    class='rounded-circle img-fluid'
                    src='https://mrmonyman.com/assets/img/about/3.jpg'
                    alt='Our secure office for transactions'
                  />
                </div>
                <div class='timeline-heading'>
                  <h4>Comfortable • Private • Secure</h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>
                    You can feel confident with your decision to do business
                    with Confidential Gold Buyers. Its a good move!
                  </p>
                </div>
                <Reviews></Reviews>
                <a
                  className='btn-primary'
                  href='https://mrmonyman.com/process.html'
                  target='_blank'
                >
                  Get more information
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id='prods'>
          <h2 class='wbuy'>What We Buy</h2>
          <h5 class='estate'>Estate and Family Trust Liquidations</h5>
          <div class='col-md-4'>
            <div class='text-center'>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src='https://mrmonyman.com/assets/img/prods/gold.jpg'
                  alt='Gold and scrap gold'
                />
              </div>
              <div class='timeline-heading'>
                <h4>Gold (10k to 24k)</h4>
              </div>
              <div class='timeline-body'>
                <p class='text-muted'>
                  Necklaces • Chains • Class Rings • Gold Watches • Wedding
                  Rings • Bracelets • Earrings • Unwanted Pieces• Dental Gold •
                  Any type of gold jewelry
                </p>
              </div>
            </div>
          </div>
          <div class='col-md-4'>
            <div class='text-center'>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src='https://mrmonyman.com/assets/img/prods/silverd.jpg'
                  alt='Silver coins'
                />
              </div>
              <div class='timeline-heading'>
                <h4>Silver Coins</h4>
              </div>
              <div class='timeline-body'>
                <p class='text-muted'>
                  ~Pre 1964 Silver Dollars/Halves/Quarters/Dimes/• 1965-70 40%
                  Silver Half Dollars
                </p>
              </div>
            </div>
          </div>
          <div class='col-md-4'>
            <div class='text-center'>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src='https://mrmonyman.com/assets/img/prods/jewelry.jpg'
                  alt='Silver items'
                />
              </div>
              <div class='timeline-heading'>
                <h4>Silver Items</h4>
              </div>
              <div class='timeline-body'>
                <p class='text-muted'>
                  Sterling Silver .925 Jewelry & Flatware
                </p>
              </div>
            </div>
          </div>
          <div class='col-md-4'>
            <div class='text-center'>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src='https://mrmonyman.com/assets/img/prods/bars.jpg'
                  alt='Silver and gold bars'
                />
              </div>
              <div class='timeline-heading'>
                <h4>Bullion Rounds and Bars</h4>
              </div>
            </div>
          </div>
        </section>
        <section id='contact'>
          <h2>Ready to connect?</h2>
          <div style={{ textAlign: "center" }}>
            <InstructionsComponent></InstructionsComponent>
          </div>
          <div class='wrap'>
            <Cam></Cam>
          </div>

          <div className='hero-action-bottom'>
            <a
              className='btn-primary'
              href='#'
              onClick={() =>
                window.open("https://calendly.com/brian-hughs", "_blank")
              }
            >
              Schedule a phone call
            </a>
          </div>
        </section>
        <ContactSection></ContactSection>
      </main>
      {/* Footer */}
      <footer class='footer'>
        <div className='row'>
          <div className='col-sm-6'>
            <a
              class='btn btn-dark btn-social mx-2'
              href='https://www.facebook.com/MrMonyMan'
              aria-label='Facebook'
              target='_blank'
              rel='noreferrer'
            >
              <img
                class='fb'
                src='https://mrmonyman.com/assets/img/icons/fb.png'
                height='60'
                width='60'
                alt='Facebook icon'
              />
            </a>
          </div>
          <div className='col-sm-6'>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://mrmonyman.com/privacy.html'
            >
              Privacy Policy
            </a>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://mrmonyman.com/terms.html'
            >
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
